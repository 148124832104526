// NPM Requirements
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { clearError } from '../actions/errorActions';

import '../../../public/static/stylesheets/fonts.css';
import '../../stylesheets/admin.postcss';

// Components
import { Footer, Admin, VendorDashboard, CateringDashboard, TruckManage, NewTruck, UnsubscribeShiftAvailable, ManageCateringRequest, CustomerCateringRequestDetail, Contact, Account, ForgotPassword, ChangePassword, Error404Page, Error500Page } from './AdminLayout.imports-loadable';
import PrivateRoute from './auth/PrivateRoute';
import Meta from './shared/Meta';
import ErrorModal from './shared/ErrorModal';

class Layout extends Component {

  componentDidMount() {
    document.documentElement.classList.add('admin-layout');
    document.documentElement.classList.remove('public-layout');
  }

  componentDidUpdate(prevProps, prevState) {
    const { errors } = this.props;
    const { pathname: currentPath } = this.props.location;
    const { pathname: prevPath } = prevProps.location;

    if (currentPath !== prevPath) {
      if (errors.status) {
        this.props.clearError();
      }
    }
  }

  render() {
    const { errors, location } = this.props;

    let showPage = true;
    if (errors.status === 404 && !errors.modal) {
      showPage = false;
    }

    let showFooter = false;
    if (!location.pathname.startsWith('/admin') &&
        !location.pathname.startsWith('/catering') &&
        (!location.pathname.startsWith('/vendor') || location.pathname.startsWith('/vendor/truck/new'))) {
      showFooter = true;
    }

    return (
      <div className="admin-root">
        <Meta />
        <Helmet>
          <script src="https://js.stripe.com/v3/" async></script>
        </Helmet>
        <div className="MainContent">
          {(errors.status === 500 && !errors.modal) &&
            <Error500Page />
          }

          {(errors.status === 404 && !errors.modal) &&
            <Error404Page />
          }

          {showPage &&
            <Switch>
              <PrivateRoute
                vendorRoute
                history={this.props.history}
                path="/vendor/truck/new"
                component={NewTruck}
              />
              <PrivateRoute
                vendorRoute
                path="/vendor/truck/:id/"
                history={this.props.history}
                component={TruckManage}
              />
              <PrivateRoute
                path="/vendor/unsubscribe-shift-available/"
                history={this.props.history}
                component={UnsubscribeShiftAvailable}
              />
              <PrivateRoute
                vendorRoute
                path="/vendor/"
                history={this.props.history}
                component={VendorDashboard}
              />
              <PrivateRoute
                path="/welcome"
                history={this.props.history}
                component={Account}
              />
              <PrivateRoute
                adminRoute
                path="/admin"
                history={this.props.history}
                component={Admin}
              />
              <PrivateRoute
                cateringRoute
                path="/catering/dashboard"
                history={this.props.history}
                component={CateringDashboard}
              />
              <PrivateRoute
                cateringRoute
                path="/catering/contact"
                history={this.props.history}
                component={Contact}
              />
              <PrivateRoute
                cateringRoute
                path="/catering/requests/new/:step?"
                history={this.props.history}
                component={ManageCateringRequest}
              />
              <PrivateRoute
                cateringRoute
                path="/catering/requests/:request_id/edit/:step?"
                history={this.props.history}
                exact
                component={ManageCateringRequest}
              />
              <PrivateRoute
                cateringRoute
                path="/catering/requests/:request_id"
                exact
                history={this.props.history}
                component={CustomerCateringRequestDetail}
              />
              <Route
                path="/forgot-password"
                component={ForgotPassword}
              />
              <PrivateRoute
                path="/change-password/"
                history={this.props.history}
                component={ChangePassword}
              />
              <PrivateRoute
                path="/account/"
                history={this.props.history}
                component={Account}
              />
              <Route
                exact
                path="/error"
                component={Error500Page}
              />
              <Route
                exact
                path="/not-found"
                component={Error404Page}
              />
              <Route
                component={Error404Page}
              />
            </Switch>
          }
          <ErrorModal />
        </div>
        { showFooter &&
          <Footer
            history={this.props.history}
          />
        }
      </div>
    );
  }
}

Layout.propTypes = {
  children: PropTypes.object,
  history: PropTypes.object,
  clearError: PropTypes.func,
  errors: PropTypes.object,
  location: PropTypes.object
};

function mapStateToProps(state, props) {
  return {
    errors: state.errors,
    auth: state.auth
  };
}

function mapDispatchToProps(dispatch) {
  return {
    clearError() {
      dispatch(clearError());
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
